/**
 * Splash screen
 */
const overlay = document.querySelector('.splash');
const page = document.querySelector('.page__wrapper');
const bgtransform = document.querySelector('.bg')
var delay = (function() {
  var timer = 0;
  return function(callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

function hideOverlay() {
  console.log('hide splash');
  overlay.classList.add('hide');
  page.classList.add('show');
  bgtransform.classList.add('bg--1');
  delay(function() {
    console.log('make scrollable');
    page.classList.add('scroll');
  }, 1000);


  overlay.removeEventListener('click', hideOverlay);
  window.removeEventListener('wheel', hideOverlay);
  window.removeEventListener('touchmove', hideOverlay);
}

overlay.addEventListener('click', hideOverlay);
window.addEventListener('wheel', hideOverlay);
window.addEventListener('touchmove', hideOverlay);


/**
 * Background switch
 */

let scrollpos = window.scrollY
const body = document.querySelector(".bg")
const footer = document.querySelector(".footer")
const container_1 = document.getElementById("section-3")
const container_2 = document.getElementById("section-4")
const container_1_pos = container_1.offsetTop
const container_2_pos = container_2.offsetTop
const add_class_scroll_c1 = () => body.classList.add("bg--2")
const rm_class_scroll_c1 = () => body.classList.remove("bg--2")
const add_class_scroll_c2 = () => body.classList.add("bg--3")
const rm_class_scroll_c2 = () => body.classList.remove("bg--3")
const add_class_scroll_foot = () => footer.classList.add("is-hidden")
const rm_class_scroll_foot = () => footer.classList.remove("is-hidden")

window.addEventListener('scroll', function() {
  scrollpos = window.scrollY;
  if (scrollpos >= container_1_pos) { add_class_scroll_c1() } else { rm_class_scroll_c1() }
  if (scrollpos >= container_2_pos) { add_class_scroll_c2() } else { rm_class_scroll_c2() }
  if (scrollpos >= container_2_pos) { add_class_scroll_foot() } else { rm_class_scroll_foot() }
  console.log(scrollpos)
})


/**
 * Force new tab/window on external links
 */

$("a[href^=http]").click(function() {
  if (this.href.indexOf(location.hostname) == -1) { // TODO: Add support for subdomains and /subpages
    $(this).attr({
      target: "_blank",
      title: "Opens in a new window"
    });
  }
})
